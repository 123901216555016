import React, { useState } from 'react';

import { makeStyles, Container } from '@material-ui/core';

import Logo from 'src/components/logo';

import LoginForm from './loginForm';
import ResetPasswordForm from './resetPasswordForm';

const useStyles = makeStyles(theme => ({
  content: {
    backgroundColor: '#fff',
    height: '100vh',
    padding: theme.spacing(5, 0),
    display: 'flex',
    flexDirection: 'column',
  },
  logoWrapper: {
    width: 200,
    margin: '0 auto',
  },
  container: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
  },
}));

export default function DesktopLogin() {
  const classes = useStyles();
  const [resetPassword, setResetPassword] = useState(false);

  const handleResetPassword = () => {
    setResetPassword(true);
  };

  return (
    <div className={classes.content}>
      <div className={classes.logoWrapper}>
        <Logo />
      </div>
      <div className={classes.container}>
        <Container>
          {!resetPassword ? <LoginForm resetPassword={handleResetPassword} /> : <ResetPasswordForm />}
        </Container>
      </div>
    </div>
  );
}
