import React, { useState } from 'react';
import { navigate } from 'gatsby';
import { makeStyles, Dialog, IconButton } from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';

import StepsImage from 'src/components/stepsImage';

import LoginForm from './loginForm';
import ResetPasswordForm from './resetPasswordForm';
import { useEffect } from 'react';

const useStyles = makeStyles(theme => ({
  dialog: {
    '& .MuiBackdrop-root': {
      backgroundColor: 'rgba(0,0,0,0.5)',
    },
    '& .MuiDialog-paper': {
      width: 750,
    },
  },
  content: {
    maxWidth: 475,
    margin: 'auto',
    zIndex: 2,
  },
  imageWrapper: {
    width: '50%',
    marginTop: -70,
    zIndex: 1,
  },
  header: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingBottom: theme.spacing(2),
  },
}));

export default function DesktopLogin() {
  const classes = useStyles();
  const [resetPassword, setResetPassword] = useState(false);

  const handleResetPassword = () => {
    setResetPassword(true);
  };

  return (
    <Dialog maxWidth="lg" open className={classes.dialog}>
      <div className={classes.header}>
        <IconButton
          onClick={() => {
            navigate('/');
          }}
        >
          <CloseIcon color="secondary" />
        </IconButton>
      </div>
      <div className={classes.content}>
        {!resetPassword ? <LoginForm resetPassword={handleResetPassword} /> : <ResetPasswordForm />}
      </div>
      <div className={classes.imageWrapper}>
        <StepsImage step="step1" />
      </div>
    </Dialog>
  );
}
