import React, { useEffect } from 'react';

import SEO from 'src/components/seo';
import Login from 'src/features/login';

export default function Onboarding() {
  useEffect(() => {
    // if (!window.dataLayer) {
    //   window.dataLayer = [];
    // }


    // window.dataLayer.push(
    //   {
    //     "identity": {
    //       "account": {
    //         "id": "1"
    //       },
    //       "user": {
    //         "id": "1"
    //       },
    //     },
    //     "context": {
    //       "staging": "true",
    //       "impersonating": "false",
    //       "role": "user"
    //     },
    //     "accountProperties": {
    //       "created_at": "1669113246"
    //     }
    //   }
    // );

    // window.dataLayer.push({
    //   "event": "submitted calculator",
    //   "context": {
    //     "calculatorName": "11111",
    //     "*placeholder for field name*": "2222"
    //   },
    //   "accountProperties": {
    //     "created_at": "1669113246"
    //   }
    // });

  }, []);

  return (
    <>
      <SEO title="Login" />
      <Login />
    </>
  );
}
