import React from 'react';

import { Hidden } from '@material-ui/core';

import DesktopLogin from './desktopLogin';
import MobileLogin from './mobileLogin';

export default function () {
  return (
    <>
      <Hidden only="xs">
        <DesktopLogin />
      </Hidden>

      <Hidden smUp>
        <MobileLogin />
      </Hidden>
    </>
  );
}
