/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { navigate } from 'gatsby';
import { useFormik } from 'formik';
import * as yup from 'yup';
import queryString from 'query-string';
import {
  makeStyles,
  IconButton,
  Typography,
  FormControl,
  InputLabel,
  OutlinedInput,
  FormHelperText,
  Link,
  Button,
  Hidden,
} from '@material-ui/core';

import api from 'src/services/axios';

import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import Alert from 'src/components/alert';

import login from 'src/api/login';
import { getToken } from 'src/api/login';
import { setCurrentUser, getRedirectUrl } from 'src/api/_common';

const useStyles = makeStyles(theme => ({
  buttonWrapper1: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingBottom: theme.spacing(5),
  },
  buttonWrapper2: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: theme.spacing(5),
    '& > *': {
      height: 53,
      [theme.breakpoints.down('sm')]: {
        width: 250,
      },
    },
  },
  icon: {
    margin: theme.spacing(0, 1),
  },
}));

const formSchema = yup.object().shape({
  email: yup
    .string()
    .email()
    .required('this field is required.'),
  password: yup.string(),
});

export default function LoginForm({ resetPassword }) {
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const redirectUrl = getRedirectUrl();

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: formSchema,
    onSubmit: async (values, actions) => {
      actions.setSubmitting(true);
      const { status, message } = await login(values);
      if (status === 'success') {
        setIsLoggedIn(true);
        // navigate('/me/overview/', { replace: true });
      } else {
        setError(message);
      }
    },
  });

  const code = queryString.parse(location.search) && queryString.parse(location.search).code ? queryString.parse(location.search).code : null;

  useEffect(async () => {
    if (!code) {
      return;
    }
    const { status, message } = await getToken({ code });
    if (status === 'success') {
      setIsLoggedIn(true);
      // navigate('/me/overview/', { replace: true });
    }
  }, [code]);

  useEffect(async () => {
    if (!isLoggedIn) {
      return;
    }

    const { data: me } = await api.get('/me');

    setCurrentUser(me.data.user);

    if (!window.dataLayer) {
      window.dataLayer = [];
    }

    window.dataLayer.push(
      {
        "identity": {
          "account": {
            "id": me.data.user.id,
          },
          "user": {
            "id": me.data.user.id,
          },
        },
        "context": {
          "staging": process.env.DEVELOPMENT_LOGOUT,
          "impersonating": "false",
          "role": me.data.user.user_role,// "user"
        },
      }
    );

    window.dataLayer.push({
      "event": "login",
      "context": {
        "staging": process.env.DEVELOPMENT_LOGOUT,
      },
      "accountProperties": {
        "created_at": Date.now(),
      }
    });

    if (redirectUrl) {
      navigate(redirectUrl, { replace: true });
    } else {
      navigate('/me/overview/', { replace: true });
    }
  }, [isLoggedIn]);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmitSearch = (event) => {
    event.preventDefault();

    if (formik.isValid) {
      formik.submitForm();
    }
  };

  return (
    <>
      <Typography variant="h1" align="center">
        Log in
      </Typography>
      <form className={classes.form}>
        <FormControl variant="outlined" fullWidth margin="normal">
          <InputLabel htmlFor="email">Email</InputLabel>
          <OutlinedInput
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            id="email"
            label="Email"
            name="email"
            value={formik.values.email}
          />
          <FormHelperText component="div">
            {formik.errors.email && formik.touched.email ? <Alert severity="error">{formik.errors.email}</Alert> : null}
          </FormHelperText>
        </FormControl>
        <FormControl variant="outlined" fullWidth margin="normal">
          <InputLabel htmlFor="password">Password</InputLabel>
          <OutlinedInput
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            id="password"
            label="Password*"
            name="password"
            type={showPassword ? 'text' : 'password'}
            value={formik.values.password}
            endAdornment={
              <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} edge="end">
                {showPassword ? (
                  <VisibilityOff color="primary" className={classes.icon} />
                ) : (
                  <Visibility color="primary" className={classes.icon} />
                )}
              </IconButton>
            }
          />
          <FormHelperText component="div">
            {formik.errors.password && formik.touched.password ? (
              <Alert variant="filled" severity="error">
                {formik.errors.password}
              </Alert>
            ) : null}
          </FormHelperText>
        </FormControl>

        <div className={classes.buttonWrapper1}>
          <Button color="primary" size="small" onClick={resetPassword}>
            Reset password
          </Button>
        </div>
        <Typography variant="subtitle2" align="center">
          By clicking Log in button, you agree to Leverly International Inc. <Link>Terms and Conditions</Link>,
          <Link>Privacy Policy</Link>, <Link>Fair Processing Notice</Link> and <Link>Cookie Policy</Link>.
        </Typography>
        <FormHelperText component="div">{error && <Alert severity="error">{error}</Alert>}</FormHelperText>
        <div className={classes.buttonWrapper2}>
          <Button
            color="primary"
            variant="contained"
            disableElevation
            type="submit"
            disabled={formik.isSubmitting}
            onClick={(event) => handleSubmitSearch(event)}
            endIcon={
              <Hidden smDown>
                <ArrowForwardIcon className={classes.icon} />
              </Hidden>
            }
          >
            Log in
          </Button>
        </div>
      </form>
    </>
  );
}
