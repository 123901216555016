/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { navigate } from 'gatsby';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {
  makeStyles,
  Typography,
  FormControl,
  InputLabel,
  OutlinedInput,
  FormHelperText,
  Button,
} from '@material-ui/core';

import Alert from 'src/components/alert';

import { sendResetPassword } from 'src/api/login';

const useStyles = makeStyles(theme => ({
  buttonWrapper2: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    '& > *': {
      height: 53,
      width: 215,
      margin: theme.spacing(1),
      [theme.breakpoints.down('sm')]: {
        width: 250,
      },
    },
  },
  icon: {
    margin: theme.spacing(0, 1),
  },
  text: {
    padding: theme.spacing(5, 0, 3),
  },
}));

const formSchema = yup.object().shape({
  usernameEmail: yup.string().required('this field is required.'),
});

export default function ResetPasswordForm() {
  const classes = useStyles();
  const [passwordRested, setPasswordRested] = useState(false);
  const [error, setError] = useState(false);

  const formik = useFormik({
    initialValues: {
      usernameEmail: '',
    },
    validationSchema: formSchema,
    onSubmit: async (values, actions) => {
      actions.setSubmitting(true);

      const { status, message } = await sendResetPassword(values.usernameEmail);

      setError(false);

      if (status === 'success') {
        setPasswordRested(true);
        navigate('/me/overview/', { replace: true });
      } else {
        setError(message)
      }
    },
  });

  return (
    <>
      <Typography variant="h1" align="center" paragraph>
        Reset password
      </Typography>
      <Typography variant="subtitle2" align="center" className={classes.text}>
        Please enter your username or email address. You will receive an email message with instructions on how to reset
        your password.
      </Typography>
      <form className={classes.form}>
        <FormControl variant="outlined" fullWidth margin="normal">
          <InputLabel htmlFor="email">Username or email</InputLabel>
          <OutlinedInput
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            id="usernameEmail"
            label="Username or email"
            name="usernameEmail"
            value={formik.values.usernameEmail}
            error={error && formik.touched.usernameEmail}
          />

          {error && formik.touched.usernameEmail && (
            <FormHelperText component="div">
              <Alert severity="error">{error}</Alert>
            </FormHelperText>
          )}

          {passwordRested && <Alert>Please check your email .......</Alert>}
        </FormControl>

        {/* <FormHelperText component="div">{error && <Alert severity="error">{error}</Alert>}</FormHelperText> */}

        <div className={classes.buttonWrapper2}>
          <Button color="primary" variant="contained" disableElevation onClick={formik.submitForm}>
            Get new password
          </Button>
          <Button
            color="primary"
            onClick={() => {
              navigate('/');
            }}
          >
            back to Leverly
          </Button>
        </div>
      </form>
    </>
  );
}
